import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HomeIcon, PrinterIcon, ShareIcon } from '@heroicons/react/outline';

import { DiagnosesContext } from '../contexts/DiagnosesContext';
import type { Diagnosis } from '../contexts/DiagnosesContext';
import DiagnosisDetails from './DiagnosisDetails';
import { languages } from '../languages';

export default function Share() {
  const { diagnosisId } = useParams<{ diagnosisId: string }>();
  const { diagnoses } = useContext(DiagnosesContext);
  const [diagnosis, setDiagnosis] = useState<Diagnosis | null>(null);
  const [loading, setLoading] = useState(true);
  const [showLanguages, setShowLanguages] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Simple print function
  const handlePrint = () => {
    window.print();
  };

  // Share function
  const handleShare = async () => {
    if (!diagnosis) return;
    
    // Try to use the Web Share API if available
    const shareUrl = window.location.href;
    
    if (navigator.share) {
      try {
        await navigator.share({
          title: diagnosis.name,
          url: shareUrl,
        });
        return;
      } catch (error) {
        // Fall back to clipboard if sharing fails
        console.error('Error sharing:', error);
      }
    }

    // Copy to clipboard as fallback
    try {
      await navigator.clipboard.writeText(shareUrl);
      // Could add toast notification here
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setShowLanguages(false);
  };

  useEffect(() => {
    if (diagnoses.length > 0) {
      setLoading(false);
      
      // Find diagnosis by ID
      const foundDiagnosis = diagnoses.find(d => d.id === diagnosisId);

      if (foundDiagnosis) {
        setDiagnosis(foundDiagnosis);
      } else {
        // Diagnosis not found, navigate to 404 page
        navigate('/404');
      }
    }
  }, [diagnoses, diagnosisId, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse text-congress-blue-600">
          <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    );
  }

  if (!diagnosis) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold text-congress-blue-900">
          <Trans t={t}>Ingen diagnose funnet</Trans>
        </h1>
        <Link to="/" className="mt-4 text-congress-blue-600 hover:text-congress-blue-800">
          <Trans t={t}>Gå tilbake til startsiden</Trans>
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 print:bg-white">
      {/* Custom simple header with just the essential controls */}
      <header className="bg-white shadow-sm print:hidden">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-6">
            <Link 
              to="/" 
              className="inline-flex items-center text-congress-blue-600 hover:text-congress-blue-800"
              title={t('Gå tilbake til startsiden')}
            >
              <HomeIcon className="h-5 w-5" />
            </Link>
            
            <button
              onClick={handleShare}
              className="inline-flex items-center text-congress-blue-600 hover:text-congress-blue-800"
              title={t('Del denne diagnosen')}
            >
              <ShareIcon className="h-5 w-5" />
            </button>
            
            <button
              onClick={handlePrint}
              className="inline-flex items-center text-congress-blue-600 hover:text-congress-blue-800"
              title={t('Skriv ut denne diagnosen')}
            >
              <PrinterIcon className="h-5 w-5" />
            </button>
            
            {/* Language selector as dropdown */}
            <div className="relative">
              <select
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                className="bg-white border border-gray-300 rounded-md py-1 pl-3 pr-8 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-congress-blue-500 focus:border-congress-blue-500"
                aria-label={t('Språk')}
              >
                {languages.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </header>

      <main className="py-10 print:py-0">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 print:px-0">
          {/* Diagnosis header */}
          <div className="mb-8 print:mb-4">
            <h1 className="text-3xl font-bold text-congress-blue-900 print:text-black text-center">
              {diagnosis.name}
            </h1>
            
            {diagnosis.nameAndCodeOrphanet && (
              <p className="mt-2 text-sm text-gray-600 print:text-gray-800 text-center">
                {diagnosis.nameAndCodeOrphanet}
              </p>
            )}
          </div>
          
          {/* Main content area */}
          <div className="bg-white shadow rounded-lg print:shadow-none">
            <div className="px-6 py-8 print:p-0">
              <DiagnosisDetails 
                expected={diagnosis.expected}
                geneAssociation={diagnosis.geneAssociation}
                generalFindings={diagnosis.generalFindings}
                matchExpected={diagnosis.matchExpected}
                matchSupportives={diagnosis.matchSupportives}
                name={diagnosis.name}
                nameAndCodeOrphanet={diagnosis.nameAndCodeOrphanet}
                oralFindings={diagnosis.oralFindings}
                prevalence={diagnosis.prevalence}
                references={diagnosis.references}
                rights={diagnosis.rights}
                supportives={diagnosis.supportives}
                synonymsEnglish={diagnosis.synonymsEnglish}
              />
            </div>
          </div>
          
          {/* Back button */}
          <div className="mt-8 flex justify-center print:hidden">
            <Link 
              to="/directory" 
              className="inline-flex items-center rounded-md border border-transparent bg-congress-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-congress-blue-600 focus:outline-none focus:ring-2 focus:ring-congress-blue-500 focus:ring-offset-2"
            >
              <Trans t={t}>Tilbake til alle diagnoser</Trans>
            </Link>
          </div>
          
          {/* Print footer */}
          <div className="hidden print:block print:mt-8 print:pt-4 print:border-t print:border-gray-200 print:text-center print:text-gray-500 print:text-sm">
            {t('Unike tenner')} &copy; {new Date().getFullYear()}
          </div>
        </div>
      </main>
    </div>
  );
}
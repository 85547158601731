import type { Diagnosis } from '../contexts/DiagnosesContext';

/**
 * Opens the share page in a new window and triggers print
 * @param diagnosis The diagnosis to print
 * @param shouldPrint Whether to trigger the print dialog automatically
 */
export function openSharePageForPrint(diagnosis: Diagnosis, shouldPrint: boolean = true): void {
  if (!diagnosis.id) return;
  
  const shareUrl = `${window.location.origin}/share/${diagnosis.id}`;
  const printWindow = window.open(shareUrl, '_blank');
  
  if (printWindow && shouldPrint) {
    // Add a slight delay to allow the page to fully load before printing
    printWindow.addEventListener('load', () => {
      setTimeout(() => {
        printWindow.print();
      }, 500);
    });
  }
}
import { ShareIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type ShareButtonProps = {
  diagnosisId?: string;  // Now using ID instead of name
  diagnosisName: string; // Still keep name for display purposes
};

export default function ShareButton({ diagnosisId, diagnosisName }: ShareButtonProps) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  
  // Only enable the share button if we have an ID
  const hasId = !!diagnosisId;
  
  // Generate the share URL using the ID
  const shareUrl = hasId 
    ? `${window.location.origin}/share/${diagnosisId}`
    : '';

  const handleShare = async () => {
    if (!hasId) return;
    
    // Try to use the Web Share API if available
    if (navigator.share) {
      try {
        await navigator.share({
          title: diagnosisName,
          url: shareUrl,
        });
        return;
      } catch (error) {
        // Fall back to clipboard if sharing fails
        console.error('Error sharing:', error);
      }
    }

    // Copy to clipboard as fallback
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  return (
    <button
      onClick={handleShare}
      disabled={!hasId}
      className={`ml-2 flex items-center rounded-md px-2.5 py-1.5 text-xs font-medium ${
        hasId 
          ? 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50' 
          : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'
      }`}
      title={hasId ? t('Del denne diagnosen') : t('Deling er ikke tilgjengelig for denne diagnosen')}
    >
      <ShareIcon className="mr-1 h-3 w-3" aria-hidden="true" />
      {copied ? <Trans t={t}>Kopiert!</Trans> : <Trans t={t}>Del</Trans>}
    </button>
  );
}
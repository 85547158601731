import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import DisordersDirectory from './components/DisordersDirectory';
import FAQ from './components/FAQ';
import Validation from './components/Validation';
import { DiagnosesProvider } from './contexts/DiagnosesContext';
import { SidePanelProvider } from './contexts/SidePanelStateContext';
import { SignsProvider } from './contexts/SignsContext';
import FourOFour from './layouts/FourOFour';
import Home from './layouts/Home';
import HomeLayout from './layouts/Home/HomeLayout';
import Match from './layouts/Match';
import Share from './layouts/Share';

export default function App() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{t('Unike tenner')}</title>
        <html lang={i18n.language} />
        <meta
          name="description"
          content={t(
            'A search tool to find unusual diagnoses for jaw and mouth',
          )}
        />
      </Helmet>
      <SignsProvider>
        <DiagnosesProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/search"
              element={
                <SidePanelProvider>
                  <Match />
                </SidePanelProvider>
              }
            />
            <Route path="/directory" element={<DisordersDirectory />} />
            <Route path="/share/:diagnosisId" element={<Share />} />
            <Route
              element={
                <HomeLayout>
                  <Outlet />
                </HomeLayout>
              }
            >
              <Route path="*" element={<FourOFour />} />
              <Route path="/validation" element={<Validation />} />
              <Route path="/faq" element={<FAQ />} />
            </Route>
          </Routes>
        </DiagnosesProvider>
      </SignsProvider>
    </>
  );
}

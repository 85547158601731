import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon, PrinterIcon } from '@heroicons/react/outline';
import { Trans, useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/solid';

import type { Diagnosis } from '../../contexts/DiagnosesContext';
import Chip from '../Chip';
import DiagnosisDetails from '../DiagnosisDetails';
import ShareButton from './ShareButton';
import { openSharePageForPrint } from '../../utils/printUtils';
//import Tooltip from '../Tooltip';

// New component for action buttons
function ActionButtons({ diagnosis }) {
  const { t } = useTranslation();

  const handlePrint = () => {
    if (diagnosis.id) {
      openSharePageForPrint(diagnosis);
    }
  };

  return (
    <div className="flex space-x-4 mb-2 justify-end">
      <ShareButton 
        diagnosisId={diagnosis.id} 
        diagnosisName={diagnosis.name} 
      />
      
      <button 
        onClick={handlePrint}
        disabled={!diagnosis.id}
        className={`ml-2 flex items-center rounded-md px-2.5 py-1.5 text-xs font-medium ${
          diagnosis.id 
            ? 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50' 
            : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'
        }`}
        title={diagnosis.id ? t('Skriv ut denne diagnosen i ny fane') : t('Utskrift ikke tilgjengelig')}
      >
        <PrinterIcon className="mr-1 h-3 w-3" aria-hidden="true" />
        <Trans t={t}>Åpne i utskriftsvisning</Trans>
      </button>
    </div>
  );
}

type Props = {
  diagnosis: Diagnosis;
  showChips: boolean;
};

export default function Disorder({ diagnosis, showChips = false }: Props) {
  const { t } = useTranslation();

  return (
    <Disclosure
      as="li"
      className="border-ra overflow-hidden bg-white shadow first:rounded-t-md last:rounded-b-md"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="block w-full truncate hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 lg:px-6">
              <div className="mr-4 flex-shrink-0 self-start">
                <ChevronRightIcon
                  className={` ${
                    open ? 'rotate-90' : 'rotate-0'
                  } h-6 w-4 transition delay-100 ease-in-out`}
                  aria-hidden="true"
                />
              </div>
              <div className="min-w-0 flex-1 lg:flex lg:items-center lg:justify-between">
                <div className="flex truncate">
                  <p className="text-grey-600 text-base font-medium">
                    {diagnosis.name}
                  </p>
                </div>

                <div className="mt-2 flex-shrink-0 lg:ml-5 lg:mt-0">
                  <div className="flex space-x-1 overflow-hidden">
                    {diagnosis?.rarity && (
                      <div>
                        <Chip variant="neutral">
                          <abbr 
                            title={diagnosis?.prevalence}
                            className="flex max-w-max content-center items-center no-underline"
                          >
                          <div className="sr-only">
                            <Trans t={t}>Kategorisering</Trans>
                          </div>
                            {diagnosis?.rarity}
                            <InformationCircleIcon
                              className="ml-1 h-4 w-4 text-azure-500"
                              aria-hidden="true"
                            />
                          </abbr>
                        </Chip>
                        {/*
                        <Tooltip.Content position="left">
                          <p>{diagnosis?.prevalence}</p>
                        </Tooltip.Content>
                        */}
                      </div>
                    )}
                    {showChips && (
                      <>
                        <Chip variant="success">
                          <div className="mr-1 text-gray-500">
                            <Trans t={t}>Forventede</Trans>
                          </div>
                          {diagnosis?.matchExpected}/{diagnosis?.expected.size}
                        </Chip>
                        <Chip variant="warning">
                          <div className="mr-1 text-gray-500">
                            <Trans t={t}>Støttende</Trans>
                          </div>
                          {diagnosis?.matchSupportives}
                        </Chip>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="h-0 opacity-0"
            enterTo="h-fit opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="h-fit opacity-100"
            leaveTo="h-0 opacity-0"
          >
            <Disclosure.Panel static as="div" className="py-2 px-14">
              {/* Action buttons shown before diagnosis details */}
              <ActionButtons diagnosis={diagnosis} />
              
              <DiagnosisDetails showChips={showChips} {...diagnosis} />

              {/* Action buttons shown after diagnosis details */}
              <ActionButtons diagnosis={diagnosis} />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}